<template>
  <component
    :is="asElement"
    v-if="show && testimonials.length"
    class="testimonials-section --testimonials"
    :class="{ section: asElement === 'section', '--single': isSingle }"
  >
    <div
      v-for="testimonial in testimonials"
      :key="testimonial.name"
      class="testimonial"
      :class="{ '--with-image': testimonial.image }"
      data-aos="fade-up"
    >
      <div class="content">
        <div
          v-if="testimonial.image"
          class="image-container"
          :style="{ '--parallax-duration': '1.1s' }"
          data-parallax="img"
        >
          <img
            class="image"
            width="100"
            height="100"
            v-lazy-load :data-src="testimonial.image.mediaItemUrl"
            alt=""
            role="presentation"
          />
        </div>
        <div class="text-content" data-parallax="quote">
          <div class="quote">“</div>
          <p class="desc">
            {{ testimonial.content }}
          </p>
          <p class="sub">{{ testimonial.name }}</p>
          <p class="sub">{{ testimonial.position }}</p>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import { applyParallax } from '@/utils/custom-parallax'

export default {
  name: 'Testimonials',
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    testimonials: {
      type: Array,
      required: true,
    },
    asElement: {
      type: String,
      default: 'section',
    },
  },
  computed: {
    isSingle() {
      return this.testimonials.length < 2
    },
  },
  mounted() {
    const parallaxQuotes = document.querySelectorAll('[data-parallax="quote"]')
    parallaxQuotes.forEach((img) => applyParallax(img, -4, 30))
    const parallaxImages = document.querySelectorAll('[data-parallax="img"]')
    parallaxImages.forEach((img) => applyParallax(img, -8, 80))
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/components/common/sections/AppTestimonialsSection';
</style>
