import { isElementInViewport } from './is-element-in-viewport'

const parallax = (img, parentNode, speed, percentageFromTop) => {
  const imgY = parentNode.getBoundingClientRect().top
  const winY = window.pageYOffset
  const winH = window.innerHeight
  const parentH = parentNode.getBoundingClientRect().height
  let imgPercent

  // The next pixel to show on screen
  const winBottom = winY + winH

  // If block is shown on screen
  if (isElementInViewport(img)) {
    // Number of pixels shown after block appear
    const imgBottom = (winBottom - imgY) * speed
    // Max number of pixels until block disappear
    const imgTop = winH + parentH
    // Porcentage between start showing until disappearing
    imgPercent = percentageFromTop + (imgBottom / imgTop) * 5 + (50 - speed * 10)
    img.style.transform = `translate(0, ${imgPercent.toFixed(2)}px)`
  }
}

export const applyParallax = (img, speed = 0.4, percentageFromTop = 0) => {
  const parentNode = img.parentNode

  parallax(img, parentNode, speed, percentageFromTop)
  window.addEventListener('scroll', () => {
    requestAnimationFrame(() => parallax(img, parentNode, speed, percentageFromTop))
  })
}
